import React, { useEffect, useState } from "react";
import styles from "./MyDealer.module.css";
import { Link } from "react-router-dom";
import { getDealerApi } from "../../../services/MarketWorking/MyTeam/getDealerApi";
import _ from "lodash";
import { Button } from "react-bootstrap";
import GoogleMap from "./GoogleMap";
import { checkedInapi } from "../../../services/MarketWorking/MyTeam/checkInApi";
import VisitDetails from "./VisitDetails";
import { useSelector, connect } from "react-redux";
import { RiDirectionFill } from "react-icons/ri";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../Common/SearchableSelect.css";
import CommonSnackbar from "../../Common/CommonSnackbar";
import Loader from "../../Common/Loader";
import { getZSMTerritoryApi } from "../../../services/zsm/getZSMTerritoryApi";
import { getUniqueListBy } from "../../Common/Function";
import Message from "../../Common/Message";
import { MdLocationOff } from "react-icons/md";
import { getDealerNameApi } from "../../../services/rmdm/getDealerNameApi";
import { getRmdmWssApi } from "../../../services/rmdm/getRmdmWssApi";
import PowerBi from "../../../Pages/PowerBi";
import MyDealerSummary from "./MyDealerSummary";
import MyDealerStatus from "./MyDealerStatus";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const clusterCodePlaceHolder = {
  SH3_Name__c: null,
  SH3_Code__c: null,
  Cluster_Description__c: "",
  CLUSTER_CODE__c: "",
};
const territoryAutoCompletePlaceHolder = {
  attributes: null,
  Id: null,
  SH2_Name__c: null,
  SH2_Code__c: null,
  Name: null,
  SH3_Code__c: null,
};

const dealerPlaceHolder = {
  attributes: null,
  Id: null,
  Dealer_Name__c: null,
  DealerClass__c: null,
  DealerType1__c: null,
  DealerLatitude__c: null,
  DealerLongitude__c: null,
  PrimaryTSICode__c: null,
  DealerCode__c: null,
  RecordTypeId: null,
};

const MyDealers = ({
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getDealerNameApi,
}) => {
  const [dealerOption, setDealerOption] = useState([]);
  const [error, setError] = useState("");
  const [selectedTerritory, setSelectedTerritory] = useState( territoryAutoCompletePlaceHolder );
  const [selectLatLong, setSelectLatLong] = useState(dealerPlaceHolder);
  const [lat, setLat] = useState(18.516726);
  const [long, setLong] = useState(73.856255);
  const [dealer, setDealer] = useState("");
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const [territoryCheckIn, setTerritoryCheckIn] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tname = useSelector((state) => state?.territory?.territoryDetails);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [locationError, setLocationError] = useState(false);
  const [dealerCode, setDealerCode] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [currentAccuracyFactor, setCurrentAccuracyFactor] = useState(0);
  const territoryOption = useSelector( (state) => state?.territory?.territoryDetails );
  const [isDealerData, setIsDealerData] = useState("Loading");
  const [checkinFlag, setCheckinFlag] = useState(false);
  const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
  const clusterCodeOption = useSelector( (state) => state?.clusterCode?.clusterCodeList );
  const tourPlanDefaultData = useSelector( (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records );
  const [autoCheckInFlag, setAutoCheckInFlag] = useState(false);
  const [getSummeryList, setGetSummeryList] = useState({});
  const [reloadDealerStatusFlag, setReloadDealerStatusFlag] = useState(false);
  const salesGroupCode = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c);
  const [isChecked, setIsChecked] = useState(false);

  //---------------------------------------------- snackbar ----------------------------------------------------------- //
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
    setSuccessOpen(false);
  };

  //---------------------------------------------- Dealer ------------------------------------------------------------- //

  // set lattitude and longittitude
  useEffect(() => {
    if (selectLatLong) {
      setLat(selectLatLong?.DealerLatitude__c);
      setLong(selectLatLong?.DealerLongitude__c);
      setDealer(selectLatLong?.Dealer_Name__c);
      setDealerCode(selectLatLong?.DealerCode__c);
      setAccuracy(parseInt(selectLatLong?.GPSAccuracy__c));
    }
  }, [selectLatLong]);

  // Dealer Name API call

  const getDealerData = async (SH2_Code__c) => {
    try {
      const response = await getDealerApi(SH2_Code__c);
      if (response?.success) {
        if (response?.data?.length === 0) {
          setIsDealerData("false");
          return { msg: "No data found" };
        } else {
          setDealerOption(response?.data?.Dealer);
          setIsDealerData("true");
          return response?.data?.Dealer;
        }
      } else {
        setError(response?.msg);
        return null;
      }
    } catch (error) {
      console.error("Error fetching dealer data:", error);
      setError("Failed to fetch dealer data.");
      return null;
    }
  };

  // ------------------------------------------- Territory ------------------------------------------------------ //

  // Get Selected Territory
  useEffect( () => {
    setIsDealerData("Loading")
    if (selectedTerritory) {
      const checkInTerritory = selectedTerritory?.Id;
      setTerritoryCheckIn(checkInTerritory);
      if (selectedTerritory?.SH2_Code__c !== null) {
        let SH2_Code__c = selectedTerritory?.SH2_Code__c;
        (async () => {
          const response = await getDealerApi(SH2_Code__c, isChecked);
          if (response?.success) {
            if (response?.data?.Dealer?.length === 0) {
              setIsDealerData("false")
            } else {
              setDealerOption(response?.data?.Dealer);
              // setSelectedTerritory("");
              setIsDealerData("true")
            }
          } else {
            setError(response?.msg);
          }
        })();
      }
    }
  }, [selectedTerritory, isChecked]);

  // useEffect(() => {
  //   setIsDealerData("Loading")
  //   if (selectedTerritory?.SH2_Code__c !== null) {
  //     let SH2_Code__c = selectedTerritory?.SH2_Code__c;
  //     getDealerData(SH2_Code__c);
  //   }
  // }, [selectedTerritory]);

  // to get territory picklist when user is ZSM
  useEffect(() => {
    if (!_.isEmpty(sh3CodeForTerritory)) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList("");
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  //--------------------------------- Google Map / Navigation / Distance calculation------------------------------------ //
  
  // open default google map
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" + parseFloat(lat) + "," + parseFloat(long)
    );
  };

  // open default google map
  const showInMapClicked2 = () => {
    window.open(
      "https://maps.google.com?q=" + parseFloat(currentLatitude) + "," + parseFloat(currentLongitude)
    );
  };

  //to get lat,lon from current location of user
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentAccuracyFactor(position.coords.accuracy);
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [selectedTerritory, selectLatLong]);

  // checkIn validation
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * 1000; // Distance in m
    return d;
  }

  // Helper function to convert degrees to radians
  function toRad(deg) {
    return deg * (Math.PI / 180);
  }

  const distance = calcDistance(currentLatitude, currentLongitude, lat, long);

  // For checking location access yes or not

  useEffect(() => {
    checkPermission();  
  }, []);

  const checkPermission = async () => {
    try {
      const { state } = await navigator.permissions.query({
        name: "geolocation",
      });
      if (state === "denied") {
        setLocationError(true);
      }
    } catch (error) {
    }
  };

  //--------------------------------------- Auto-Populate Cluster & territory ---------------------------------------- //

  // trigger Point for Set default value
  useEffect(() => {
    setDefaultValues();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    return d.toLocaleDateString('en-US', options);
  };

  // Auto select the default Value for Territoy & cluster 
  const setDefaultValues = () => {

    var clusterFilterData = getUniqueListBy(clusterCodeOption, userType === "MH3" || userType === "RBDM Collaborator" ? "CLUSTER_CODE__c" : "SH3_Name__c");

    if (tourPlanDefaultData.length != 0) {
      const mtpCurrentDateData = tourPlanDefaultData.filter(item => formatDate(item.MTP_Date__c) === formatDate(new Date()));
      if (userType === "SH6" || userType === "SH5" || userType === "MH3" || userType === "RBDM Collaborator") {
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].Cluster_es__c !== null) {
          const originalString = mtpCurrentDateData[0].Cluster_es__c;
          const parts = originalString.split(':');
          var defaultCluster = clusterFilterData.filter(item => item.CLUSTER_CODE__c === parts[1]);
          if (_.isEmpty(defaultCluster)){
            var defaultCluster = clusterFilterData.filter(item => item.SH3_Code__c === parts[1]);
          }
          setCluster(defaultCluster[0]);
          setSH3CodeForTerritory(parts[1])
        }
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
          const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
          var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
          if (_.isEmpty(defaultTerritory)) {
            var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
          }
          setSelectedTerritory(defaultTerritory[0]);
        }
      } else {
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
          const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
          var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
          if (_.isEmpty(defaultTerritory)) {
            var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
          }
          setSelectedTerritory(defaultTerritory[0]);
        }
      }
    }
  }

  // -------------------------------------- My Dealer Summary Check-In -------------------------------------------- //
  // To find the entry in List 
  const getEntry = (array, value, key) => {
    return array.find(entry => entry[key] === value);
  }

  // Auto Populate the value get form my wss summery
  const handleAutoPoulatedValue = async (value) => {
    var dealerData = {};
    var territoryData = {};
    if (territoryOption){
      var tty = getEntry(territoryOption, value.tsicode, "SH2_Code__c");
  
      if (tty !== undefined && tty !== null) {
        territoryData = {
          attributes: tty.attributes,
          Id: tty.Id,
          SH2_Name__c: tty.SH2_Name__c,
          SH2_Code__c: value.tsicode,
          Name: null,
          SH3_Code__c: tty.SH3_Code__c,
        };
        setSelectedTerritory(territoryData);
      } else {
        setFailesOpen(true);
        setError("Territory Not Found");
      }
    };

    // Get Dealer List using Territory Code & get other Dealer details 
    const dealerOptionData = await getDealerData(value.tsicode);

    if (dealerOptionData){
      // Function call to get entry for specific territory code
      var dealerEntry = getEntry(dealerOptionData, value.dealercode, "DealerCode__c");

      if (dealerEntry !== undefined && dealerEntry !== null){
        var dealerData = {
          attributes: dealerEntry.attributes,
          Id: dealerEntry.Id,
          Dealer_Name__c: value.dealername,
          DealerClass__c: dealerEntry.DealerClass__c,
          DealerType1__c: dealerEntry.DealerType1__c,
          DealerLatitude__c: value.dealerlatitude,
          DealerLongitude__c: value.dealerlongitude,
          GPSAccuracy__c: dealerEntry.GPSAccuracy__c,
          PrimaryTSICode__c: value.tsicode,
          DealerCode__c: value.dealercode,
          RecordTypeId: dealerEntry.RecordTypeId,
        }
        setSelectLatLong(dealerData);
      }else{
        setFailesOpen(true);
        setError("Dealer Not Found");
      }
    }

    // Set AutoCheck In flag to identify check it done by Auto Or mannual 
    setAutoCheckInFlag(true);

  }
  
  // To trigger check-In After value get auto-populated 
  useEffect(() => {
    (async () => {
      if (!_.isEmpty(territoryCheckIn) && !_.isEmpty(dealer) && !_.isEmpty(selectLatLong) && autoCheckInFlag === true) {
        await checkIn({ preventDefault: () => { } });
      }
    })();
  }, [territoryCheckIn, dealer, autoCheckInFlag]);


  const reloadDealerStatus = () => {
    setReloadDealerStatusFlag(true);
  }

  const disableReloadFlag = () => {
    setReloadDealerStatusFlag(false);
  }

  // -------------------------------------------- Dealer Check In ----------------------------------------------------//
  
  // On-submit checkIn button
  const checkIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Early return for location off
    if (currentLatitude === 0 && currentLongitude === 0) {
      setLoading(false);
      setError("Please turn on your location");
      setFailesOpen(true);
      return;
    }

    // Early return for missing required fields
    if (_.isEmpty(territoryCheckIn) || _.isEmpty(dealer)) {
      setLoading(false);
      setError("Please select cluster, territory & dealer name!");
      setFailesOpen(true);
      return;
    }

    if (isNaN(accuracy) || accuracy === null || accuracy === undefined){
      setLoading(false);
      setError("Please reach out the admin - We could not locate this dealer accuracy");
      setFailesOpen(true);
      return;
    }

    // Distance calculation and check
    const calculatedDistance = 2 * (accuracy + currentAccuracyFactor) + 300;
    if (distance <= calculatedDistance || (_.isEmpty(lat) && _.isEmpty(long))) {
      let response;

      // Conditional API calls based on userType
      if (userType !== "MH3" && userType !== "RBDM Collaborator" && territoryCheckIn !== "") {
        response = await checkedInapi(
          "Visit Plan",
          territoryCheckIn,
          dealer,
          dealerCode,
          "",
          "",
          currentLatitude,
          currentLongitude,
          "Valid",
          lat,
          long,
          distance,
          accuracy,
          currentAccuracyFactor
        );
      } else if (userType === "MH3" || userType === "RBDM Collaborator") {
        response = await checkedInapi(
          "Visit Plan",
          cluster?.Id,
          dealer,
          dealerCode,
          "",
          "",
          currentLatitude,
          currentLongitude,
          "Valid",
          lat,
          long,
          distance,
          accuracy,
          currentAccuracyFactor
        );
      }

      setLoading(false);
      if (response?.success) {
        setSuccessOpen(true);
        setAutoCheckInFlag(false);
        if (autoCheckInFlag) {
          setCheckinFlag(true);
        }
      } else {
        setError(response?.msg);
        setFailesOpen(true);
        setAutoCheckInFlag(false);
      }
      // setCluster(clusterCodePlaceHolder);
      // setSelectLatLong(dealerPlaceHolder);
      // setSelectedTerritory(territoryAutoCompletePlaceHolder);
      setGetSummeryList(selectedTerritory);
    } else {
      // Invalid check-in case
      let response = await checkedInapi(
        "Visit Plan",
        territoryCheckIn,
        dealer,
        dealerCode,
        "",
        "",
        currentLatitude,
        currentLongitude,
        "Invalid",
        lat,
        long,
        distance,
        accuracy,
        currentAccuracyFactor
      );
      setLoading(false);
      setFailesOpen(true);
      if (response?.success) {
        setError("Dealer Check-in Unsuccessful");
        setAutoCheckInFlag(false);
      } else {
        setError(response?.msg);
        setAutoCheckInFlag(false);
      }
      setGetSummeryList(selectedTerritory);

      // setSelectedTerritory(territoryAutoCompletePlaceHolder);
      // setSelectLatLong(dealerPlaceHolder);
    }
  };

  // ------------------------------------------------------------------------------------------------------------//

  //----------------------------------------- Jointly Adopted Dealer -------------------------------------------//

  // To enable & disable jointly adopted swich button
  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked); // Update state with the switch value
  };

  //------------------------------------------------------------------------------------------------------------//

  return (
    <>
      <Loader loading={loading} />

      {userType === "SH3" && (
        <>
          {/* My dealer Symmary Module*/}
          <MyDealerSummary CheckIndata={handleAutoPoulatedValue} getCheckInStatus={checkinFlag} reloadList={getSummeryList} isSubmittedFlag={reloadDealerStatus}/>

          {/* My dealer Status Module*/}
          <MyDealerStatus reloadList={reloadDealerStatusFlag} afterReloadFlag={disableReloadFlag}/>
        </>
      )}

      <div className={styles.myDealer__outerBox}>
        <div className="row">
          <div className={`col-4 ${styles.myDealer__heading}`}>My Dealer</div>
          <div className={`col-8 ${styles.myDealer__switchOuterBox}`}>
            {/* <label>Jointly Adopted Dealer</label>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            /> */}
            {salesGroupCode && userType &&
              ((salesGroupCode === 105 || salesGroupCode === 107) &&
                ["SH3", "SH4", "SH5", "SH6", "SH7"].includes(userType)) && (
                <>
                  <label className={styles.myDealer__switchButton}>
                    Jointly Adopted Dealer
                  </label>
                  <div className={`form-check form-switch ${styles.myDealer__switchBox}`}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={isChecked}
                      onChange={handleSwitchChange}
                    />
                  </div>
                </>
              )}
          </div>
        </div>  

        {userType === "SH7" && 
          <Autocomplete
            id="grouped-demo"
            className="myDealer__categorySelect"
            options={
              territoryOption?.length === 0
                ? [
                  {
                    SH2_Name__c: "Loading...",
                    SH2_Code__c: "",
                  },
                ]
                : getUniqueListBy(territoryOption, "SH2_Code__c")
            }
            getOptionLabel={(option) => {
              if (option?.SH2_Name__c) {
                return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
              } else {
                return "";
              }
            }}
            value={selectedTerritory}
            name="dealerId"
            onChange={(event, newValue) => {
              setSelectedTerritory(newValue);
              setSelectLatLong(dealerPlaceHolder);
              setDealerOption([]);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Territory" />
            )}
          />
        }

        {userType !== "SH7" && (
            <Autocomplete
              id="grouped-demo"
              className="myDealer__categorySelect"
              options={
                !_.isEmpty(tname) ? getUniqueListBy(tname, "SH2_Code__c") : []
              }
              getOptionLabel={(option) => {
                if (option?.SH2_Name__c) {
                  return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                } else {
                  return "";
                }
              }}
              value={selectedTerritory}
              name="dealerId"
              onChange={(event, newValue) => {
                setSelectedTerritory(newValue);
                setSelectLatLong(dealerPlaceHolder);
                setDealerOption([]);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Territory" />
              )}
            />
          )}

        <Autocomplete
          id="grouped-demo2"
          className="myDealer__categorySelect"
          options={
            isDealerData === "Loading"
              ? [
                {
                  Dealer_Name__c: "Loading...",
                  DealerCode__c: "",
                },
              ]
              : isDealerData === "false" ? [
                {
                  Dealer_Name__c: "No Data",
                  DealerCode__c: "",
                },
              ] : dealerOption
          }
          getOptionLabel={(option) => {
            if (option?.Dealer_Name__c) {
              return `${option?.Dealer_Name__c} ${option?.DealerCode__c && ":"
                } ${option?.DealerCode__c}`;
            } else {
              return "";
            }
          }}
          name="subCategory"
          value={selectLatLong}
          onChange={(event, newValue) => {
            setSelectLatLong(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Dealer Name" />
          )}
        />

        {/* <PowerBi/> */}
        {/* google mao component here */}

        <div className={styles.myDealer__googleMap_Box}>
          {locationError ? (
            <Message
              message={
                "Location access denied. Please update your browser settings to grant location permission."
              }
              className="DealerLocatorForm__mapAccess"
              icon=<MdLocationOff
                style={{ color: "#808080", height: "2em", width: "2em" }}
              />
            />
          ) : (
            <GoogleMap
              lat={_.isEmpty(lat) ? currentLatitude : parseFloat(lat)}
              long={_.isEmpty(long) ? currentLongitude : parseFloat(long)}
              zoom={12}
            />
          )}
        </div>

        <div className="row m-0 p-2">
          <div className="col-4 ps-0">
            <Link to="#">
              <Button
                className={styles.myDealer__navButton}
                onClick={showInMapClicked}
              >
                Navigate
                <RiDirectionFill
                  style={{ marginLeft: "4px", marginBottom: "1px" }}
                />
              </Button>
            </Link>
          </div>
          <div className="col-4">
            <Button
              className={styles.myDealer__navButton}
              onClick={checkIn}
            >
              Check In
            </Button>
          </div>
          <div className="col-4 pe-0">
            {/* <Link to="/past-sr-insight">
              <Button className={styles.myDealer__navButton}>
                SR/Observations
              </Button>
            </Link> */}
            <Link to="#">
              <Button
                className={styles.myDealer__navButton}
                onClick={showInMapClicked2}
              >
                Current Navigate
                <RiDirectionFill
                  style={{ marginLeft: "4px", marginBottom: "1px" }}
                />
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <CommonSnackbar
        open={failedOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={error}
      />
      <CommonSnackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"success"}
        message={`Successfully checked-in for ${!_.isEmpty(dealer) ? dealer : "this"
          }${" "}
        dealer!`}
      />

      {/* Power BI Table */}
      <div className="pbi__outerBox">
          <PowerBi
            id = "dd6b49e0-3b63-4e27-a36d-25bfdc232607"
            pageName = "856b307dcfe20f15c564"
            dealerCode = {dealerCode}
          />
      </div>

      <div className="pbi__outerBox">
        <PowerBi
          id="fe64310f-4e8e-428e-8e37-dbcc99589108"
          pageName="4d8aeed31c46261754ea"
          dealerCode = {dealerCode}
        />
      </div>

      <VisitDetails />
    </>
  );
};

export default connect(null, {
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getDealerNameApi,
})(MyDealers);
